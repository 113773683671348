import React from "react"
/** @jsx jsx */
import { jsx } from "@emotion/core"

import { StaticQuery, graphql } from "gatsby"

import Skill from "../components/skill"

class Skills extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            accounting: file(relativePath: { eq: "accounting.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            payroll: file(relativePath: { eq: "payroll.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            counseling: file(relativePath: { eq: "counseling.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <section>
            <Skill
              id="redovisning"
              title="Redovisning"
              description={
                <>
                  Överlåt företagets redovisning till Clearly för att spara
                  dyrbar tid. Det frigör resurser som kan läggas på andra
                  viktiga områden i er verksamhet.
                </>
              }
              listTitle="Fördelar med Clearly som redovisningspartner:"
              listItems={[
                "Frigör tid",
                "Effektiv hantering med hög kvalitet",
                "Bred kompetens",
                "Personlig kontakt",
              ]}
              listDescription="Clearly kan hantera hela eller delar av företagets redovisning, vi anpassar oss efter det som passar er bäst. Vi eftersträvar att göra vårt arbete effektivt med kvalitet. Vi har bred kompetens så ingen fråga är för stor. Er personliga kontakt inom Clearly ser till att ni får den hjälp ni behöver."
              image={data.accounting.childImageSharp}
              imagePosition="right"
            />
            <Skill
              id="loneadministration"
              title="Löneadministration"
              description={
                <>
                  Att hantera löner är ofta komplicerat och det är känsligt om
                  det blir fel. Clearly har kompetens och resurser för att ta
                  över företagets löneadministration så att ni kan sova gott om
                  nätterna när den 25e närmar sig.
                </>
              }
              listTitle="Fördelar med Clearly som lönepartner:"
              listItems={[
                "Kompetent hantering",
                "Bred kunskap",
                "Enlighet med rådande regler",
                "Trygghet",
              ]}
              listDescription="Vi är up to date med rådande regelverk och har bred kompetens på området så vi kan hantera även de mest komplicerade löner."
              image={data.payroll.childImageSharp}
              imagePosition="left"
            />
            <Skill
              id="radgivning"
              title="Rådgivning"
              description={
                <>
                  Det är svårt som företagare att kunna allt och tänka på allt. På
                  Clearly har vi erfarna konsulter som hjälper er att lyfta
                  blicken och se helheten.
                </>
              }
              listTitle="Fördelar med Clearly som rådgivningspartner:"
              listItems={[
                "Vi tar ett helhetsperspektiv",
                "Ökad kontroll",
                "Bättre framförhållning",
                "”Street-smarta” lösningar",
              ]}
              bulletList={[
                "Förändrad bolagsstruktur",
                "Utdelningsmöjligheter",
                "Digitalisering av bokföringen",
                "Koncernkonsolidering",
                "Koncernredovisning",
                "Kassaflödesanalys",
                "Exit",
                "Due Diligence",
              ]}
              bulletDescription="Ett axplock av de områden vi kan hjälpa er med:"
              listDescription="Vi kan hjälpa er med allt från likviditetsanalys till utveckling av bolagsstruktur. Vad är nyckeln till er framgång? Vad behöver ni göra för att nå era långsiktiga mål?"
              image={data.counseling.childImageSharp}
              imagePosition="right"
            />
          </section>
        )}
      />
    )
  }
}

export default Skills
