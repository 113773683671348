import React from "react"
/** @jsx jsx */
import { jsx } from "@emotion/core"

import { MobileRule } from "../constants"

import Customer from "../components/customer"

import skierfe from "../assets/skierfe.png"
import lociloci from "../assets/lociloci.png"
import carousel from "../assets/carousel.png"
import startStable from "../assets/star-stable.png"
import theFarm from "../assets/the-farm.png"
import notForSaleAle from "../assets/notforsale.png"
import thunderkick from "../assets/thunderkick.png"
import arvered from "../assets/arvered.png"

class Customers extends React.Component {
  render() {
    return (
      <section id="vara-kunder">
        <div
          css={{
            [MobileRule]: {
              margin: "2rem auto",
              flexWrap: "wrap",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "1264px",
            margin: "4rem auto",
          }}
        >
          <Customer logo={skierfe} />

          <Customer logo={lociloci} />

          <Customer logo={carousel} />

          <Customer logo={startStable} />

          <Customer logo={theFarm} />

          <Customer logo={notForSaleAle} />

          <Customer logo={thunderkick} />

          <Customer logo={arvered} />
        </div>
      </section>
    )
  }
}

export default Customers
