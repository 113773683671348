import React, {useEffect} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/intro"
import Skills from "../components/skills"
import Jobs from "../components/jobs"
import Customers from "../components/customers"
import Quote from "../components/quote"
import Contact from "../components/contact"



const IndexPage = () => {

  useEffect(() => {
    // Update the document title using the browser AP
  }, []);

  return (
  <Layout>
    <SEO title="Redovisning &amp; rådgivning" />
    <Intro />
    <Jobs />
    <Skills />
    <Quote />
    <Customers />
    <Contact />
  </Layout>)
}

export default IndexPage
