import React from "react"
import Slider from "react-slick";
import '../components/slick.css';
/** @jsx jsx */
import { jsx } from "@emotion/core"
import { MobileRule } from "../constants"
import quote1 from "../assets/quote1.png"
import quote2 from "../assets/quote2.png"

class Intro extends React.Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 800,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };
    return (
      <section id="citat"
      css = {{
        [MobileRule]: {
          padding: "2rem 0.5rem",
        },
        padding: "2rem",
        backgroundColor: "#f4f5ef",
      }}
      >
      <Slider {...settings}>
        <div
          css= {{
            display: "flex !important",
            flexDirection: "row",
            margin: "auto",
            justifyContent: "center",
            alignItems: "center",
            height: "250px",
          }}
        >
          <img 
          css={{
            [MobileRule]: {
              height: "2rem",
            },
            height: "3rem",
          }}
           src={quote1} alt="quote" />

          <div
          css={{
            [MobileRule]: {
              fontSize: "1rem",
              padding: "1rem",
            },
              color: "rgb(58, 66, 77)",
              fontSize: "1.25rem",
              fontStyle: "italic",
              lineHeight: "1.5rem",
              letterSpacing: "0px",
              textAlign: "center",
              padding: "2rem",
              maxWidth: "500px",
            }}
          >
            Bästa redovisningsbyrån, utan konkurrens, är Clearly AB.
            Snabba, kunniga, smarta, lyhörda och strukturerade.
            Med Clearly AB slipper du många problem i livet.
            <div css= {{
              width: "50px", 
              height: "2px",
              backgroundColor: "#FF796F",
              margin: "1rem auto",
            }}></div>
            <span
            css = {{
              fontStyle: "normal",
              fontSize: "0.9rem",
              [MobileRule]: {
                fontSize: "0.8rem",
              }
            }}
            >
            Rockaway Street AB
            </span>
          </div>          
          <img
          css={{
            [MobileRule]: {
              height: "2rem",
            },
            height: "3rem",
          }} 
          src={quote2} alt="quote" />
        </div>
        <div
          css= {{
            display: "flex !important",
            flexDirection: "row",
            margin: "auto",
            justifyContent: "center",
            alignItems: "center",
            height: "250px",
          }}
        >
          <img 
          css={{
            [MobileRule]: {
              height: "2rem",
            },
            height: "3rem",
          }}
           src={quote1} alt="quote" />

          <div
          css={{
            [MobileRule]: {
              fontSize: "1rem",
              padding: "1rem",
            },
              color: "rgb(58, 66, 77)",
              fontSize: "1.25rem",
              fontStyle: "italic",
              lineHeight: "1.5rem",
              letterSpacing: "0px",
              textAlign: "center",
              padding: "2rem",
              maxWidth: "500px",
            }}
          >
            Tack Clearly AB för den enorma hjälpen med mitt företag. 
            Klockren service rakt igenom och proffsiga tips 
            och råd för att underlätta kontorsarbetet.
            <div css= {{
              width: "50px", 
              height: "2px",
              backgroundColor: "#FF796F",
              margin: "1rem auto",
            }}></div>
            <span
            css = {{
              fontStyle: "normal",
              fontSize: "0.9rem",
              [MobileRule]: {
                fontSize: "0.8rem",
              }
            }}
            >
            Västra Ingarö Bygg AB
            </span>
          </div>          
          <img
          css={{
            [MobileRule]: {
              height: "2rem",
            },
            height: "3rem",
          }} 
          src={quote2} alt="quote" />
        </div>
        <div
          css= {{
            display: "flex !important",
            flexDirection: "row",
            margin: "auto",
            justifyContent: "center",
            alignItems: "center",
            height: "250px",
          }}
        >
          <img 
          css={{
            [MobileRule]: {
              height: "2rem",
            },
            height: "3rem",
          }}
           src={quote1} alt="quote" />

          <div
          css={{
            [MobileRule]: {
              fontSize: "1rem",
              padding: "1rem",
            },
              color: "rgb(58, 66, 77)",
              fontSize: "1.25rem",
              fontStyle: "italic",
              lineHeight: "1.5rem",
              letterSpacing: "0px",
              textAlign: "center",
              padding: "2rem",
              maxWidth: "500px",
            }}
          >
            Mycket kompetent och bra service, med en personlig känsla!
            <div css= {{
              width: "50px", 
              height: "2px",
              backgroundColor: "#FF796F",
              margin: "1rem auto",
            }}></div>
            <span
            css = {{
              fontStyle: "normal",
              fontSize: "0.9rem",
              [MobileRule]: {
                fontSize: "0.8rem",
              }
            }}
            >
            Norbergs Glasmästeri AB
            </span>
          </div>          
          <img
          css={{
            [MobileRule]: {
              height: "2rem",
            },
            height: "3rem",
          }} 
          src={quote2} alt="quote" />
        </div>
      </Slider>
      </section>
    )
  }
}

export default Intro
