import React from "react"
import PropTypes from "prop-types"
import { MobileRule } from "../constants"

/** @jsx jsx */
import { jsx } from "@emotion/core"

class Customer extends React.Component {
  render() {
    const { logo } = this.props

    return (
      <article
        css={{
          [MobileRule]: {
            width: "calc((100% - 8rem) / 2)",
            flex: "0",
            flexBasis: "unset",
            margin: "0.5rem 2rem",
          },
          flex: "1",
          flexBasis: 0,
          margin: "auto 1rem",
        }}
      >
        <img
          src={logo}
          css={{
            "&:hover": {
              opacity: "1",
            },
            width: "100%",
            opacity: "0.5",
          }}
        />
      </article>
    )
  }
}

Customer.propTypes = {
  logo: PropTypes.string,
}

export default Customer
