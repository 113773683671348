import React from "react"
import PropTypes from "prop-types"
import checkMark from "../assets/check_mark_blue.svg"
import { MobileRule, SkillsBreakpoint } from "../constants"
import Img from "gatsby-image/withIEPolyfill"
import AnchorLink from "react-anchor-link-smooth-scroll"

/** @jsx jsx */
import { jsx } from "@emotion/core"

class Skill extends React.Component {
  render() {
    const {
      title,
      description,
      listTitle,
      listItems,
      listDescription,
      image,
      imagePosition,
      bulletList,
      bulletDescription,
      id,
    } = this.props

    const listItemsElements = listItems.map((item, index) => (
      <li
        key={index}
        css={{ fontSize: "1rem", lineHeight: "25px", margin: "0.25rem auto" }}
      >
        {" "}
        <img src={checkMark} alt="check mark" css={{ marginRight: "0.5rem" }} />
        {item}
      </li>
    ))

    const bulletListItems = bulletList
      ? bulletList.map((bullet, index) => (
          <li
            key={index}
            css={{
              fontSize: "1rem",
              lineHeight: "25px",
              margin: "0.25rem auto",
            }}
          >
            {bullet}
          </li>
        ))
      : null

    return (
      <article id={id} css={{ display: "block" }}>
        <div
          css={{
            [MobileRule]: {
              flexDirection: "column",
            },
            [SkillsBreakpoint]: {
              // maxHeight: "700px",
            },
            height: "auto",
            width: "100%",
            display: "flex",
            flexDirection: imagePosition === "left" ? "row" : "row-reverse",
            margin: "auto",
          }}
        >
          <div
            css={{
              [MobileRule]: {
                height: "20rem",
                width: "100%",
              },
              width: "50%",
              flex: 1,
              position: "relative",
            }}
          >
            <Img
              css={{
                height: "100%",
                ["div"]: {
                  paddingBottom: "inherit!important",
                },
                ".gatsby-image-wrapper div": {
                  paddingBottom: "0",
                },
              }}
              alt={title}
              fluid={image.fluid}
              fadeIn={false}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </div>
          <div
            css={{
              [MobileRule]: {
                width: "100%",
                justifyContent: "center",
              },
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent:
                imagePosition === "left" ? "flex-start" : "flex-end",
            }}
          >
            <div
              css={{
                [MobileRule]: {
                  margin: "2.5rem 2.5rem 4rem 2.5rem",
                  overflow: "hidden",
                },
                maxWidth: "580px",
                margin:
                  imagePosition === "left"
                    ? "4rem 100px 4rem 3.5rem"
                    : "4rem 3.5rem 4rem 84px",
              }}
            >
              <h2
                css={{
                  [MobileRule]: {
                    fontSize: "1.5rem",
                  },
                  color: "#FF8479",
                  fontSize: "2rem",
                  fontWeight: "600",
                }}
              >
                {title}
              </h2>
              <p css={{ fontSize: "1rem", lineHeight: "25px" }}>
                {description}
              </p>
              <h4
                css={{
                  color: "#FF8479",
                  fontSize: "1rem",
                  fontWeight: "600",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                {listTitle}
              </h4>
              <ul css={{ listStyle: "none", padding: 0 }}>
                {listItemsElements}
              </ul>
              <p
                css={{
                  fontSize: "1rem",
                  lineHeight: "25px",
                  marginTop: "2rem",
                  marginBottom: bulletDescription ? "1rem" : "3rem",
                }}
              >
                {listDescription}
              </p>
              {bulletDescription && (
                <p css={{ fontSize: "1rem", lineHeight: "25px" }}>
                  {bulletDescription}
                </p>
              )}
              {bulletListItems && (
                <ul css={{ paddingLeft: "1.25rem", paddingBottom: "2rem" }}>
                  {bulletListItems}
                </ul>
              )}
              <AnchorLink
                offset={90}
                href="#kontakt"
                css={{
                  padding: "0.75rem 2rem",
                  textDecoration: "none",
                  backgroundColor: "#323943",
                  color: "white",
                  fontSize: "1rem",
                  fontWeight: 600,
                  cursor: "pointer",
                  borderRadius: "25px",
                  display: "inline-block",
                }}
              >
                Kontakta oss
              </AnchorLink>
            </div>
          </div>
        </div>
      </article>
    )
  }
}

Skill.defaultProps = {
  listItems: [],
}

Skill.propTypes = {
  title: PropTypes.string,
  description: PropTypes.element,
  listTitle: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string),
  listDescription: PropTypes.string,
  image: PropTypes.object,
  imagePosition: PropTypes.string,
}

export default Skill
