import React from "react"
/** @jsx jsx */
import { jsx } from "@emotion/core"
import { MobileRule } from "../constants"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

class Intro extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            cert1: file(relativePath: { eq: "certs/ski-cert.png" }) {
              childImageSharp {
                fixed(height: 70, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            cert2: file(relativePath: { eq: "certs/srf-cert-svart-text.png" }) {
              childImageSharp {
                fixed(height: 60, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            cert3: file(relativePath: { eq: "certs/triple.png" }) {
              childImageSharp {
                fixed(height: 60, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            cert4: file(relativePath: { eq: "certs/bv-cert.png" }) {
              childImageSharp {
                fixed(height: 60, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <section>
            <div
              css={{
                [MobileRule]: { flexDirection: "column", padding: "2rem" },
                padding: "100px 60px",
                display: "flex",
                maxWidth: "1264px",
                margin: "auto",
              }}
            >
              <div css={{ marginRight: "1rem", flex: 1 }}>
                <p css={{ lineHeight: "25px" }}>
                  Clearly är en Stockholmsbaserad redovisnings- och
                  rådgivningsbyrå med ett skickligt och hängivet team av
                  kompetenta ekonomer.
                  <br />
                  <br />
                  Vi kan hjälpa er med allt från bokföring, fakturering, löner,
                  deklarationer och bokslut till annan företagsekonomisk
                  rådgivning såsom likviditetsprognos, koncernkonsolidering,
                  analys av bolagsstruktur etc. Med personligt engagemang frigör
                  vi er tid så ni kan ägna er åt det ni är bäst på.
                </p>
              </div>
              <div
                css={{
                  [MobileRule]: { marginLeft: 0 },
                  marginLeft: "1rem",
                  flex: 1,
                }}
              >
                <p css={{ lineHeight: "25px" }}>
                  Vi på Clearly ser utmaningar som möjligheter. Med
                  skräddarsydda processer som passar er verksamhet arbetar vi
                  effektivt med kvalitet i fokus för att hjälpa ert företag att
                  växa och bli konkurrenskraftigt. Kontakta oss så bokar vi ett
                  möte då vi gärna berättar mer!
                </p>
                <div css={{ display: "flex", alignItems: "flex-start", [MobileRule]: {flexDirection: 'column', alignItems: 'flex-start'} }}>
                  <div css={{whiteSpace: 'nowrap', marginTop: '10px'}}>
                    <b>Linda Sjögren</b>
                    <br />
                    VD Clearly AB
                  </div>
                </div>
                <div css={{[MobileRule]:{paddingTop:'1.5rem', flexWrap:'wrap'},display: 'flex', alignItems: 'center', paddingTop: '1.5rem'}}>
                    <a href="http://www.kvalitetsindex.se/branschundersokningar/redovisning/" target="_blank" css={{display: 'flex'}}><Img alt="SKI certificate" css={{"&:hover": {}, marginRight: '1rem'}} fixed={data.cert1.childImageSharp.fixed} /></a>
                    <a href="https://www.srfkonsult.se/om-srf/" target="_blank" css={{display: 'flex'}}><Img alt="Srf certifikate" css={{"&:hover": {},}} fixed={data.cert2.childImageSharp.fixed} /></a>
                    <Img alt="Srf certifikate" css={{"&:hover": {},display:'flex'}} fixed={data.cert3.childImageSharp.fixed} />
                    <a href="https://www.branschvinnare.se/" target="_blank" css={{display: 'flex'}}><Img alt="Branschvinnare certifikate" css={{"&:hover": {},}} fixed={data.cert4.childImageSharp.fixed} /></a>
                </div>
              </div>
            </div>
          </section>
        )}
      />
    )
  }
}

export default Intro
